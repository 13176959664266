@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 262 100% 4%;
        --foreground: 0 0% 94%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 259 100% 60%;
        --primary-foreground: 0 0% 94%;

        /*              #11002e Deep Purp */
        --deeppurple: hsl(262, 100%, 9%);
        /*              #7433FF Highlight Purp */
        --placeholder2: rgb(116, 51, 255);
        /*              #A175FF Main Purp */
        --placeholder3: hsl(259, 100%, 73%);
        /*              #6E7FFF Main blu */
        --placeholder4: rgb(112, 129, 255);
        /*              #070013 Midnight Dark */
        --placeholder5: hsl(262, 100%, 4%);
        /*              #4057FE Deep Blu */
        --placeholder6: rgb(62, 85, 254);
        /*              #FFADAD Pastel Re */
        --placeholder7: hsl(0, 100%, 84%);
        /*              #CAFFBF Pastel Gree */
        --placeholder8: hsl(110, 100%, 87%);
        /*              #FFD6A5 Pastel Yello */
        --placeholder9: hsl(33, 100%, 82%);
        /*              #EFEFEF Cream */
        --placeholder9: hsl(0, 0%, 94%);

        --secondary: 233 99% 62%;
        --secondary-foreground: 262 100% 9%;
        --muted: 262 100% 4%;
        --muted-foreground: 0 0% 56.4%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;
        --border: 259 100% 60%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;
        --radius: 0.5rem;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --primary-bs: 291 100% 54%;
        --secondary-bs: 191 100% 54%;
        --secondary-btn: 233 100% 72%;
        --tertiary: 259 100% 73%;
        --bodytext: 0 0% 24%;
        --salmon: 0 100% 84%;
        --neongreen: 110 100% 87%;
        /* Firefox */

        /* Chrome, Edge, and Safari */

        *::-webkit-scrollbar {
            width: 5px;
        }

        *::-webkit-scrollbar-track {
            background: #07001333;
            border-radius: 5px;
        }

        *::-webkit-scrollbar-thumb {
            background-color: #7433ff22;
            border-radius: 14px;
            border: 3px solid #7433ff22;
        }
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;
        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
    [cmdk-list] {
        height: 65vh;
        overflow-y: auto;
    }
    [cmdk-root] {
        width: 100%;
    }
    @media (max-height: 750px) {
        .hide-on-smh {
            display: none;
        }

        .mt-sidebar-smh {
            margin-top: 2rem;
        }
    }
    @media (min-height: 750px) {
        .mt-sidebar-smh {
            margin-top: 15svh;
        }
    }

    .type-writer::after {
        content: '|';
        animation: blink 1s infinite;
    }

    .type-writer > span {
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-family: var(--font-din);
    }
}
